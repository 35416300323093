
export default defineNuxtPlugin((nuxtApp) => {

    const store = useStore()

    // 1. getting mobile app version


    let agent = navigator.userAgent //+ ' AppMobileName: Złote Tarasy , AppMobileVersion: 2.1.0 ' // todo remove added string in production

    /*
    let mobileData = agent.match(/AppMobileName:\s*([\S\s][^,]+).*AppMobileVersion:\s*([\s\d\.]+)/)
    let mobileApp = mobileData && mobileData.length === 3 || false
    let mobileAppVersion = mobileApp && mobileData[2].trim() || null
    let mobileAppName = mobileApp && mobileData[1].trim() || null

     */

    let mobileData = agent.match(/MobileVersion:\s*([\s\d\.]+).*AppMobileName:\s*([\w\s]+)/)
    let mobileApp = mobileData && mobileData.length === 3 || false
    let mobileAppVersion = mobileApp && mobileData[1] || null
    let mobileAppName = mobileApp && mobileData[2] || null
    if (!mobileData) {
        mobileData = agent.match(/AppMobileName:\s*([\S\s][^,]+).*AppMobileVersion:\s*([\s\d\.]+)/)
        mobileApp = mobileData && mobileData.length === 3 || false
        mobileAppVersion = mobileApp && mobileData[2] || null
        mobileAppName = mobileApp && mobileData[1] || null
    }

    store.mobileApp = !!mobileAppName
    store.mobileAppName = mobileAppName
    store.mobileAppVersion = mobileAppVersion
    store.embedded = window !== window.parent
    store.runContext = store.mobileApp ? 'mobile' : (store.embedded ? 'embedded':'browser')


    // 2. setup error handlers


})
