import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import {
    getFirestore,
    initializeFirestore,
    persistentLocalCache,
    clearIndexedDbPersistence
} from 'firebase/firestore'
import {getInstallations,getId} from 'firebase/installations'

export default defineNuxtPlugin(async nuxtApp=>{
    const store = useStore()
    const firebaseConfig = {
        "zlote-tarasy": {
            apiKey: "AIzaSyDmtiBotYlGikXwGFGveA_QPr-mvmiVgUU",
            authDomain: "zlote-tarasy-new.firebaseapp.com",
            databaseURL: "https://zlote-tarasy-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "zlote-tarasy",
            storageBucket: "zlote-tarasy.appspot.com",
            messagingSenderId: "468790130317",
            appId: "1:468790130317:web:bff675213311a2f94394c4",
            measurementId: "G-3HS1MS22ND"
        },
        "mall-promo-epp":{
            apiKey: "AIzaSyAED8f_0vBIs0FxMkc5S4GPVdpYr0JLjL8",
            authDomain: "mall-promo-epp-new.firebaseapp.com",
            databaseURL: "https://mall-promo-epp-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "mall-promo-epp",
            storageBucket: "mall-promo-epp.appspot.com",
            messagingSenderId: "517148788130",
            appId: "1:517148788130:web:aad19f621a458c3cf75efb",
            measurementId: "G-HFBP31DZ2G"
        },
        "whitestar-sloneczna":{
            apiKey: "AIzaSyBwCbBxaM1HVYKJLCak_WLrGfI0ydFvgi0",
            authDomain: "whitestar-sloneczna.firebaseapp.com",
            databaseURL: "https://whitestar-sloneczna-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "whitestar-sloneczna",
            storageBucket: "whitestar-sloneczna.appspot.com",
            messagingSenderId: "238197918583",
            appId: "1:238197918583:web:bc9a643590b63af6986f72",
            measurementId: "G-M802FCVKEG"
        }
    }

    // 1. setup firebase project

    const runtimeConfig = useRuntimeConfig()
    const {appConfig} = runtimeConfig.public  // get project settings
    const isDev = process.env.NODE_ENV==='development'

    let config
    let app
    if(isDev){
        config = firebaseConfig[ appConfig.projectId ]
        app = initializeApp( config )
    } else {
        config = firebaseConfig[ appConfig.projectId ]
        app = initializeApp( config )
    }
    getAnalytics(app)

    // 2. configure data persistence

    if(appConfig.clearData || store.embedded){
        await clearIndexedDbPersistence(getFirestore()).catch(err=>{
            console.log('FIRESTORE CACHE ERROR',err.message)
        })
        console.log('FIRESTORE: cache cleared')
    } else {
        try {
            initializeFirestore(app, {
                localCache: persistentLocalCache(),
                useFetchStreams: false
            })
        } catch(err){
            console.error('Error in Firestore initializing',err.message)
        }
    }

    // 3. load firebase installations id
    console.log('FIRESTORE INITIALIZED',app?.name)

    getId(getInstallations()).then(id=>{
        store.system.installationId = id
    })

    // 4. load languages update

    locale.setupLanguages()  // read external translations from Firestore Bundle
})